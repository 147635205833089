import dayjs from "../_snowpack/pkg/dayjs.js";
export function updownmachine(theLog) {
  const skipWords = [
    "TestMyInter.net",
    "Sleep...",
    "Awake",
    "Resumed",
    "Starting",
    "Cleared"
  ];
  const logLineRE = /(.+M)[ ]([^ ]+)/;
  let curTime = 0;
  let curTimeStamp;
  let curStartTimeText = "";
  let evt = "";
  let ignored = "";
  let retstr = "";
  const lines = theLog.split("\n");
  ignored = lines.pop() ?? "";
  const state = {
    cond: "unknown",
    curStartTime: 0,
    prePauseCond: "",
    prePauseTime: 0
  };
  lines.forEach((line) => {
    [ignored, curTimeStamp, evt] = line.match(logLineRE) ?? [
      "",
      "",
      "badRegExp"
    ];
    curTime = Date.parse(curTimeStamp);
    if (state.curStartTime === 0) {
      state.curStartTime = curTime;
    }
    switch (evt) {
      case "Down":
        retstr += formatEvent(state.cond, state.curStartTime, curTime);
        state.cond = "Down";
        state.curStartTime = curTime;
        break;
      case "Up":
        if (state.cond === "Up") {
        } else if (state.cond === "Paused" && curTime - state.curStartTime < 3e3) {
          state.cond = "Up";
          state.curStartTime = state.prePauseTime;
          state.prePauseTime = 0;
        } else if (state.cond !== evt && state.cond === "unknown") {
          state.cond = "Up";
        } else {
          if (state.prePauseTime !== 0) {
            retstr += formatEvent(state.prePauseCond, state.prePauseTime, state.curStartTime);
            state.prePauseTime = 0;
          }
          retstr += formatEvent(state.cond, state.curStartTime, curTime);
          state.cond = "Up";
          state.curStartTime = curTime;
        }
        break;
      case "Pausing...":
        state.prePauseTime = state.curStartTime;
        state.prePauseCond = state.cond;
        state.curStartTime = curTime;
        state.cond = "Paused";
        break;
      case "Sleep":
        retstr += formatEvent(state.cond, state.curStartTime, curTime);
        state.cond = "Paused";
        state.curStartTime = curTime;
        break;
      case "badRegExp":
        retstr += `Bad RegExp! ${line}
`;
        break;
      default:
        if (!skipWords.includes(evt)) {
          retstr += `Bad evt: ${line}
`;
        }
        break;
    }
  });
  curStartTimeText = dayjs(state.curStartTime).format("MM/DD/YYYY hh:mm:ss A");
  retstr += `${curStartTimeText} ${state.cond}
`;
  return retstr;
}
function formatEvent(cond, prevms, curms) {
  const prev = dayjs(prevms).format("MM/DD/YYYY hh:mm:ss A");
  const cur = dayjs(curms).format("MM/DD/YYYY hh:mm:ss A");
  return `${prev} to ${cur} ${cond}
`;
}
