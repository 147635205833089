export class ConsoleLog {
  constructor() {
    this.consoleloglines = ["Starting consolelog"];
  }
  consolelog(text) {
    const date = new Date().toLocaleString();
    const entry = `${date} ${text}`;
    console.log(entry);
    if (this.consoleloglines.length >= 400) {
      this.consoleloglines.shift();
    }
    this.consoleloglines.push(entry);
    return "";
  }
  getlogs() {
    return this.consoleloglines.join("\n");
  }
}
export const theConsoleLog = new ConsoleLog();
export function rgb2hex(rgb) {
  const rgbMatches = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return rgbMatches && rgbMatches.length === 4 ? "#" + ("0" + parseInt(rgbMatches[1], 10).toString(16)).slice(-2) + ("0" + parseInt(rgbMatches[2], 10).toString(16)).slice(-2) + ("0" + parseInt(rgbMatches[3], 10).toString(16)).slice(-2) : "";
}
export function formatNumberMetric(fileSize, units = ["bps", "kbps", "mbps", "gbps", "tbps"]) {
  let size = Math.abs(fileSize);
  if (Number.isNaN(size)) {
    return "Invalid size";
  }
  if (size === 0) {
    return "0 " + units[0];
  }
  let quotient = Math.floor(Math.log10(size) / 3);
  quotient = quotient < units.length ? quotient : units.length - 1;
  size /= 1e3 ** quotient;
  return `${+size.toFixed(2)} ${units[quotient]}`;
}
