import {initHistoryTab, resetHistoryTab, historyLog} from "./historyTab.js";
import {
  initSpeedtestTab,
  resetSpeedtestTab,
  speedTestLog
} from "./speedtestTab.js";
import {updownmachine} from "./updownmachine.js";
import {theConsoleLog} from "./utilities.js";
const TMI_Version = "1.0.0";
const logStatsPreID = "allthelogs";
const logStatsPre = document.getElementById(logStatsPreID);
const versionElem = document.getElementById("version");
versionElem.innerText = TMI_Version;
versionElem.onclick = (e) => {
  if (checkToErase(e)) {
    return;
  }
  const sep = "===================";
  const speedLog = speedTestLog.LogToHistory("");
  const histLog = historyLog.LogToHistory("");
  const updown = updownmachine(histLog);
  const entireLog = `${sep} Up/Down ${sep}
${updown}
${sep} History ${sep}
${histLog}
${sep} Console ${sep}
${theConsoleLog.getlogs()}
${sep} Speedtest ${sep}
${speedLog}`;
  if (logStatsPre !== null) {
    logStatsPre.innerHTML = entireLog;
    $("#statsModalLong").modal("show");
  }
};
initSpeedtestTab();
initHistoryTab();
function checkToErase(e) {
  if (!e.altKey) {
    return false;
  }
  $("#erase-modal").modal("show");
  $("#modal-btn-erase").on("click", function() {
    resetHistoryTab();
    resetSpeedtestTab();
    $("#erase-modal").modal("hide");
  });
  $("#modal-btn-no").on("click", function() {
    $("#erase-modal").modal("hide");
  });
  return true;
}
