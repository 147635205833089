import {measureDownload} from "./speedtest.js";
import {formatNumberMetric, theConsoleLog} from "./utilities.js";
import {LocalStorageHistory} from "./localStorageHistory.js";
const speedLogAreaID = "SpeedTestLogs";
let speedButton;
let speedtestTimer;
const speedTestInterval = 30 * 60 * 1e3;
export let speedTestLog;
export const speedTestArea = document.getElementById(speedLogAreaID);
export function initSpeedtestTab() {
  speedButton = document.getElementById("speedtestbutton");
  speedButton.onclick = SpeedTest;
  $(".nav-tabs a").on("show.bs.tab", function(e) {
    if ($(e.target).text() === "Speed Test") {
      theConsoleLog.consolelog(`Show Speed Test: Starting Timer`);
      StartTimer();
      SpeedTest();
    } else {
      CancelTimer();
    }
  });
  $(".nav-tabs a").on("hide.bs.tab", function(e) {
    if ($(e.target).text() === "Speed Test") {
      CancelTimer();
      theConsoleLog.consolelog(`Hiding Speedtest: Canceling Timer`);
    } else {
    }
  });
  if (speedTestArea === null) {
    console.log(`Bad ID for textarea: ${speedLogAreaID}`);
    return null;
  }
  speedTestLog = new LocalStorageHistory(speedLogAreaID);
  speedTestArea.value = speedTestLog.LogToHistory("");
  return null;
}
export function resetSpeedtestTab() {
  speedTestArea.value = speedTestLog.ClearHistory();
  return null;
}
function StartTimer() {
  speedtestTimer = setInterval(() => {
    SpeedTest();
  }, speedTestInterval);
}
function CancelTimer() {
  clearInterval(speedtestTimer);
}
function SpeedTest() {
  if (speedButton.disabled) {
    theConsoleLog.consolelog("Button disabled: not starting Speedtest()");
    return;
  }
  speedButton.disabled = true;
  speedButton.innerText = "Testing speed...";
  measureDownload().then((speeds) => {
    speedTestArea.value = speedTestLog.LogToHistory(`${formatNumberMetric(speeds.bw)} `);
    theConsoleLog.consolelog(`Speedtest() done - ${formatNumberMetric(speeds.bw)}`);
    speedButton.disabled = false;
    speedButton.innerText = "Run Speed Test";
  });
}
