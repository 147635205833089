import {LocalStorageHistory} from "./localStorageHistory.js";
import {fetchTimeout} from "./speedtest.js";
import {theConsoleLog} from "./utilities.js";
import {updownmachine} from "./updownmachine.js";
const TMI_Version = "1.0.0";
const checkInterval = 30 * 1e3;
const spinnerTimeout = 1 * 1e3;
const requestTimeout = 6 * 1e3;
const hostName = "TestMyInter.net";
const logAreaID = "LogArea";
const googleFontAPIKey = "AIzaSyBbG4xUxmxkqhVpkhTpiCFKen9eQJCcTZ4";
const pingUrl = `https://fonts.gstatic.com/l/font?kit=KFOmCnqEu92Fr1Me4GZNCzcPK4I&key=${googleFontAPIKey}&v=v20`;
let averageResponse = 0;
let lastTestTick = Date.now();
export let historyLog;
export const historyTextArea = document.getElementById(logAreaID);
let historyState;
let hostEl;
const spinner = document.getElementById("spinner");
export function initHistoryTab() {
  historyLog = new LocalStorageHistory(logAreaID);
  if (historyTextArea === null) {
    console.log(`Bad ID for textarea: ${logAreaID}`);
  } else {
    historyTextArea.value = historyLog.LogToHistory(`TestMyInter.net version ${TMI_Version}`);
  }
  hostEl = document.getElementsByTagName("host")[0];
  if (hostEl === null) {
    console.log(`Bad ID for "host"`);
  }
  hostEl.onclick = () => {
    const spinner2 = document.getElementById("spinner");
    if (spinner2 === null) {
      console.log(`Bad ID for "spinner"`);
    }
    if (spinner2.style.display === "block") {
      theConsoleLog.consolelog("Already testing...");
    } else {
      theConsoleLog.consolelog("Manual check...");
      UpdateHost();
    }
  };
  window.addEventListener("beforeunload", () => {
    historyLog.LogToHistory(`Pausing...`);
  });
  window.onload = async () => {
    const resp = await fetchTimeout(pingUrl, 1e3);
    const body = await resp.arrayBuffer();
    UpdateHost();
  };
  setInterval(UpdateHost, checkInterval);
  historyState = "";
  return null;
}
export function resetHistoryTab() {
  historyState = "";
  historyTextArea.value = historyLog.ClearHistory();
  return null;
}
function UpdateHost() {
  spinner.style.display = "block";
  const now = Date.now();
  const interval = now - lastTestTick;
  if (interval > 4 * checkInterval) {
    theConsoleLog.consolelog(`Skipping test from wakeup... Last test: ${lastTestTick} Interval: 
      ${interval / 1e3}`);
    historyState = "Paused";
    historyLog.LogToHistory("Sleep", lastTestTick);
    historyLog.LogToHistory("Awake", now);
  }
  CheckAliveFetch(hostName, requestTimeout).then((status) => {
    lastTestTick = now;
    UpdateDevice(hostEl, status, interval);
  }).then(() => {
    $("#spinner").fadeOut(spinnerTimeout);
  });
}
function UpdateAverage(elapsed) {
  return Math.round(averageResponse * 0.875 + elapsed * 0.125);
}
function UpdateDevice(aHost, status, interval) {
  const elapsed = status.duration;
  let newState = "";
  const colors = {
    Up: "#4CAF50",
    Down: "#F44336",
    Paused: "#9C27B0"
  };
  if (averageResponse == 0) {
    averageResponse = elapsed;
  }
  if (status.statusText === "load") {
    newState = "Up";
    averageResponse = UpdateAverage(elapsed);
  } else if (status.statusText === "sleep") {
    newState = "Sleep... ";
  } else {
    newState = "Down";
  }
  aHost.style.backgroundColor = colors[newState];
  if (newState !== historyState) {
    historyLog.LogToHistory(newState);
  }
  historyState = newState;
  historyTextArea.value = updownmachine(historyLog.LogToHistory(""));
  theConsoleLog.consolelog(`${hostName}: "${newState}",     ${JSON.stringify(status)}     Average: ${averageResponse}, Interval: ${interval / 1e3}`);
}
async function CheckAliveFetch(host, timeout) {
  let statusText;
  const start = Date.now();
  try {
    const fetchOptions = {cache: "no-store"};
    const url = `${pingUrl}`;
    const req = await fetchTimeout(url, timeout, fetchOptions);
    statusText = "load";
  } catch (e) {
    statusText = `${e.name}: ${e.message}`;
  }
  const now = Date.now();
  return {statusText, duration: now - start};
}
