import dayjs from "../_snowpack/pkg/dayjs.js";
export class LocalStorageHistory {
  constructor(anID) {
    this.theID = "";
    this.curtext = "";
    this.localStorageAvailable = storageAvailable("localStorage");
    this.theID = anID;
    if (this.localStorageAvailable) {
      this.curtext = localStorage.getItem(this.theID) || "";
    }
  }
  LogToHistory(text, date = Date.now()) {
    if (text !== "") {
      const dateStr = dayjs(date).format("MM/DD/YYYY hh:mm:ss A");
      this.curtext += dateStr + " " + text + "\n";
      if (this.localStorageAvailable) {
        localStorage.setItem(this.theID, this.curtext);
      }
    }
    return this.curtext;
  }
  ClearHistory() {
    this.curtext = "";
    if (this.localStorageAvailable) {
      localStorage.removeItem(this.theID);
      this.LogToHistory("Cleared history... ");
    }
    return this.curtext;
  }
}
function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (e.code === 22 || e.code === 1014 || e.name === "QuotaExceededError" || e.name === "NS_ERROR_DOM_QUOTA_REACHED") && storage && storage.length !== 0;
  }
}
