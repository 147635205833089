import {theConsoleLog} from "./utilities.js";
const images = [
  {name: "image-0.png", size: 11773, timeout: 1400},
  {name: "image-1.png", size: 40836, timeout: 1200},
  {name: "image-2.png", size: 165544, timeout: 1300},
  {name: "image-3.png", size: 382946, timeout: 1500},
  {name: "image-4.png", size: 1236278, timeout: 1200},
  {name: "image-5.png", size: 4511798, timeout: 1200},
  {name: "image-6.png", size: 9092136, timeout: 1200},
  {name: "image-7.png", size: 20263246, timeout: 1200}
];
const proposedTestDur = 15e3;
const myProtocol = "https:";
const imageHost = "testmyinterimages.net";
export async function measureDownload() {
  const estImageIx = 2;
  const estImageCt = 1;
  const imagesLen = images.length;
  const estimate = await getMultipleTimes(estImageIx, estImageCt);
  theConsoleLog.consolelog(`Estimated speed: (${estImageIx} ${estImageCt}) ${JSON.stringify(estimate)}`);
  const estImageSize = images[estImageIx].size;
  let nextImageIx = estImageIx;
  while (nextImageIx <= imagesLen - 2 && estimate.dur * images[nextImageIx].size / estImageSize < proposedTestDur) {
    nextImageIx++;
  }
  const theSpeeds = await getMultipleTimes(nextImageIx, estImageCt);
  theConsoleLog.consolelog(`Measured speed (${nextImageIx} ${estImageCt}) ${JSON.stringify(theSpeeds)}`);
  return theSpeeds;
}
async function getMultipleTimes(imageNum, count) {
  const imageURL = `${myProtocol}//${imageHost}/images/image-${imageNum}.png`;
  const reqs = [];
  for (let i = 1; i <= count; i++) {
    const req = getTimes(imageURL, {timeout: proposedTestDur * 2.5});
    reqs.push(req);
  }
  const times3 = await Promise.all(reqs);
  const bytesTransferred = times3.reduce((tot, cur) => {
    return tot + cur.len;
  }, 0);
  const maxTime = Math.max(...times3.map((o) => o.xfr));
  const bw = Math.round(bytesTransferred * 8 * 1e3 / maxTime);
  return {
    bw,
    bytes: bytesTransferred,
    dur: maxTime,
    readings: times3
  };
}
export const cacheBreaker = cacheBreakerGenerator();
function* cacheBreakerGenerator() {
  let num = Math.round(Math.random() * 1e6);
  while (true) {
    num++;
    yield `cacheBreaker=${num.toString()}`;
  }
}
async function getTimes(theLink, options = {}) {
  const startTime = Date.now();
  let times;
  try {
    times = await fetchWithTimeout(`${theLink}?${cacheBreaker.next().value}`, options);
  } catch {
    const now = Date.now();
    times = {
      dur: now - startTime,
      con: now - startTime,
      xfr: now - startTime,
      len: 0,
      bogus: true
    };
  }
  return times;
}
export async function fetchWithTimeout(resource, options = {}) {
  const beg = Date.now();
  let mid = beg;
  let body;
  let len;
  try {
    const {timeout = 3e4} = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    });
    mid = Date.now();
    body = await response.arrayBuffer();
    clearTimeout(id);
  } catch (e) {
    console.log(`Timeout! ${e} body: ${JSON.stringify(body)}`);
  }
  if (body === void 0) {
    len = 0;
  } else {
    len = body.byteLength;
  }
  const end = Date.now();
  const dur = end - beg;
  const con = mid - beg;
  const xfr = end - mid;
  const retval = {
    dur,
    con,
    xfr,
    len
  };
  return retval;
}
export const fetchTimeout = (url, ms, options = {}) => {
  const controller = new AbortController();
  const promise = fetch(url, {signal: controller.signal, ...options});
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
};
